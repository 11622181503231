<template>
  <div class="top-content">
    <div class="page-header width-100p">
      <a-row class="mb-3" style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff;">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <h3 class="my-2 font-size-21">Main</h3>
        </a-col>
      </a-row>
    </div>
    <main-content
      v-if="tabs.length"
      :tabs="tabs"
      :getTabLoading="getTabLoading"
      :createTabFlag="createTabFlag"
      :sortAfterChangeDataTab="sortAfterChangeDataTab"
      @addViewscreen="openViewscreenModal()"
      @editViewscreen="openViewscreenModal"
      @changeTab="changeTab"
      @getTabData="getTabData"
    />
    <empty-main-state
      v-else
      text="Add First viewscreen"
      textBtn="Add"
      @add="openViewscreenModal()"
    />
    <a-modal
      v-model="showViewscreenModal"
      :title="viewscreenModalTitle"
      :footer="null"
      class="section-modal"
      width="800px"
      :destroyOnClose="true"
    >
      <viewscreen-modal
        ref="viewscreenModal"
        :modalTabData="modalTabData"
        :disabledAppsIds="disabledAppsIds"
        :disabledTabNames= "disabledTabNames"
        @create="createTab"
        @update="updateTab"
        @delete="deleteTab"
        @close="closeViewscreenModal"
      />
    </a-modal>
  </div>
</template>

<script>
import MainService from '@/services/api/apiMainService'

export default {
  name: 'index',
  components: {
    'main-content': () => import('./components/MainContent.vue'),
    'empty-main-state': () => import('./components/emptyMainState.vue'),
    'viewscreen-modal': () => import('./modals/viewscreenModal.vue'),
  },
  data: () => ({
    tabs: [],
    disabledAppsIds: [],
    disabledTabNames: [],
    activeTabId: 0,
    showViewscreenModal: false,
    modalTabData: null,
    getTabLoading: false,
    createTabFlag: false,
    sortAfterChangeDataTab: false,
  }),
  computed: {
    viewscreenModalTitle() {
      return this.modalTabData ? 'Edit viewscreen' : 'Add viewscreen'
    },
  },
  methods: {
    async getListOfTopTabs() {
      this.tabs = await MainService.getListOfTopTabs().then((res) => {
        this.disabledAppsIds = res.data.data.disabled_apps
        return res.data.data.tabs
      }).catch(error => {
        console.log(error)
      })
    },
    async getTabData(id) {
      this.getTabLoading = true
      try {
        await MainService.getTopTab(id).then((res) => {
          this.tabDataChange(id, res.data.data)
          this.sortAfterChangeDataTab = true
          this.$nextTick(() => {
            this.sortAfterChangeDataTab = false
          })
          return res.data.data
        }).catch(error => {
          console.log(error)
        })
      } finally {
        this.$nextTick(() => {
          this.getTabLoading = false
        })
      }
    },
    tabDataChange(id, tabData) {
      const tab = this.tabs.find(tab => tab.id === id)
      tab.apps = tabData.apps
      tab.sections = tabData.sections
    },
    createTab(newTab) {
      this.createTabFlag = true
      this.tabs.push(newTab)
      this.pushDisabledAppsIds(newTab.apps.map(app => app.application_id))
      this.$nextTick(() => {
        this.createTabFlag = false
      })
    },
    updateTab(editTabParams, oldAppsIds) {
      const editTab = this.tabs.find(tab => tab.id === editTabParams.id)
      const idxTabchange = this.tabs.findIndex(tab => tab.id === editTabParams.id)
      editTab.name = editTabParams.name
      editTab.apps = editTabParams.apps
      editTab.sections = editTabParams.sections
      this.updateDisabledAppsIds(editTabParams.apps.map(app => app.application_id), oldAppsIds)
      this.tabs.splice(idxTabchange, 1, editTab)
    },
    async changeTab(id) {
      this.activeTabId = id
      this.getTabData(id)
    },
    deleteTab(removeTab) {
      this.tabs = this.tabs.filter(tab => tab.id !== removeTab.id)
      this.removeDisabledAppsIds(removeTab.apps.map(app => app.application_id))
    },
    pushDisabledAppsIds(apps) {
      if (this.disabledAppsIds.length) {
        apps.forEach(id => {
          if (!this.disabledAppsIds.includes(id)) {
            this.disabledAppsIds.push(id)
          }
        })
      } else {
        this.disabledAppsIds.push(...apps)
      }
    },
    removeDisabledAppsIds(apps) {
      const newDisabledAppsIds = []
      this.disabledAppsIds.forEach((id) => {
        if (!apps.includes(id)) {
          newDisabledAppsIds.push(id)
        }
      })
      this.disabledAppsIds = newDisabledAppsIds
    },
    updateDisabledAppsIds(newAppsIds, oldAppsIds) {
      const removeAppsIds = []
      oldAppsIds.forEach(id => {
        if (!newAppsIds.includes(id)) {
          removeAppsIds.push(id)
        }
      })
      const pushAppsIds = []
      newAppsIds.forEach(id => {
        if (!oldAppsIds.includes(id)) {
          pushAppsIds.push(id)
        }
      })

      this.removeDisabledAppsIds(removeAppsIds)
      this.pushDisabledAppsIds(pushAppsIds)
    },
    updateDisabledAppsName() {
      this.tabs.forEach(tab => {
        // eslint-disable-next-line camelcase
        const { name, disabled_apps } = tab
        disabled_apps.forEach(appId => {
          this.disabledTabNames.push({ tabName: name, appId })
        })
      })
    },
    async openViewscreenModal(tabData) {
      if (tabData) {
        this.modalTabData = JSON.parse(JSON.stringify(tabData))
        this.showViewscreenModal = true
        return
      }
      this.modalTabData = null
      this.showViewscreenModal = true
    },
    closeViewscreenModal() {
      this.showViewscreenModal = false
      this.modalTabData = null
    },
  },
  async mounted() {
    await this.getListOfTopTabs()
    if (this.tabs?.length) {
      this.activeTabId = this.tabs[0].id
    }
    this.updateDisabledAppsName()
  },
}
</script>
<style lang="scss" />
